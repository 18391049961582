import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, useParams } from 'react-router-dom';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Particles from 'react-tsparticles';
import { loadFull } from 'tsparticles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

import './App.css';

// Importing Components
import EventList from './Components/Eventlist';
import EventDetailexcel from './Components/Eventexcel';
import Schedule from './Components/Schedule';
import ContactUs from './Components/ContactUs';
import Footer from './Components/footer';



// Main Application Component
function App() {
  return (
    <Router>
      <MainApp />
    </Router>
  );
}

// MainApp Component
function MainApp() {
  let timer;
  const location = useLocation();  // Get the current route location
  /*const [isAnimationActive, setIsAnimationActive] = useState(false);  // Control animation state

  useEffect(() => {
    // Trigger particles animation when on home page
    if (location.pathname === '/') {
      setIsAnimationActive(true); // Start the animation

      // Set a timer to stop the animation after 10 seconds
      timer = setTimeout(() => {
        setIsAnimationActive(false); // Stop the animation after 10 seconds
      }, 10000);

      
    }
    else{
      setIsAnimationActive(false);
    }
    
  }, [location.pathname]); // Run when the pathname changes*/

  return (
    <div className="App">
      {/* Particles animation only when isAnimationActive is true */}
      {/*isAnimationActive && <ParticlesBackground />*/}
      <ParticlesBackground />
      <div className="main-content">
        <Routes>
          <Route path="/" element={<EventList />} />
          <Route path="/event/:id" element={<EventDetailexcelWrapper />} />
          <Route path="/schedule" element={<Schedule />} />
        </Routes>
      </div>

      <ContactUs />
      <Footer />
      
    </div>
  );
}

// Wrapper for EventDetailexcel to handle the event ID from URL
const EventDetailexcelWrapper = () => {
  const { id } = useParams(); // Get event ID from URL
  return <EventDetailexcel eventId={id} />; // Pass the id to EventDetailexcel
};

// Particles Animation Component
const ParticlesBackground = () => {
  const particlesInit = async (main) => {
    await loadFull(main);
  };

  return (
    <Particles
      id="tsparticles"
      init={particlesInit}
      options={{
        fullScreen: { enable: true },
        particles: {
          number: {
            value: 150,
            density: {
              enable: true,
              value_area: 800,
            },
          },
          color: { value: "#808080" },  // Particle color set to #808080
          shape: { type: "circle" },
          opacity: {
            value: 0.6,
            random: true,
            anim: {
              enable: true,
              speed: 1,
              opacity_min: 0.1,
              sync: false,
            },
          },
          size: {
            value: 5,
            random: true,
            anim: {
              enable: true,
              speed: 3,
              size_min: 0.1,
              sync: false,
            },
          },
          move: {
            enable: true,
            speed: 4,
            direction: "none",
            random: true,
            straight: false,
            outModes: {
              default: "out",
            },
            attract: {
              enable: true,
              rotateX: 3000,
              rotateY: 3000,
            },
          },
          links: {
            enable: true,
            distance: 120,
            color: "#808080",  // Particle link color set to #808080
            opacity: 0.6,
            width: 1.5,
          },
        },
        background: {
          color: "transparent", // Transparent background for the particle effect
        },
        retina_detect: true,
      }}
    />
  );
};

export default App;
