import React from "react";
import "./Sponsors.css"; // For custom styles

const Sponsors = () => {
  const sponsors = [
    { id: 1, logo: "Images/title.png", alt: "Sponsor 1", text: "Title Sponsor" },
    { id: 2, logo: "Images/event.jpeg", alt: "Sponsor 2", text: "Event Sponsor" },
    { id: 3, logo: "Images/media.jpeg", alt: "Sponsor 3", text: "Media Partner" },
  ];

  // Function to wrap each letter (including spaces) of the text in a <span>
  const wrapTextWithSpans = (text) => {
    return text.split('').map((char, index) => {
      return (
        <span key={index} style={{ animationDelay: `${index * 0.2}s` }}>
          {char === ' ' ? '\u00A0' : char} {/* Replace space with non-breaking space */}
        </span>
      );
    });
  };

  return (
    <div>
      <h5 className="thanks">Thank you for your support </h5>
      <div className="sponsor-section center">
        {sponsors.slice(0, 1).map((sponsor) => (
          <div key={sponsor.id} className="sponsor">
            <a href="https://www.collegedekho.com/" style={{ textDecoration: 'none' }}>
              <img src={sponsor.logo} alt={sponsor.alt} className="image2" />
              <p className="sponsor-text">{wrapTextWithSpans(sponsor.text)}</p>
            </a>
          </div>
          
        ))}
         
      </div>
      
        <div className="sponsors-container">
          <div className="sponsor-section left">
            {sponsors.slice(1, 2).map((sponsor) => (
              <div key={sponsor.id} className="sponsor">
                <img src={sponsor.logo} alt={sponsor.alt} className="image1" />
                <p className="sponsor-text">{wrapTextWithSpans(sponsor.text)}</p>
              </div>
            ))}
          </div>

          <div className="sponsor-section left">
            {sponsors.slice(2, 3).map((sponsor) => (
              <div key={sponsor.id} className="sponsor">
                <img src={sponsor.logo} alt={sponsor.alt} className="image1" />
                <p className="sponsor-text">{wrapTextWithSpans(sponsor.text)}</p>
              </div>
            ))}
          </div>
        </div>
      </div>


    
  );
};

export default Sponsors;
