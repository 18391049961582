import React, { useEffect, useRef, useState } from "react";
import { useSwipeable } from "react-swipeable";
import "../Components/EventImage.css";

const EventImage = () => {
  const [currentImage, setCurrentImage] = useState("logo");
  const [fade, setFade] = useState(true);
  const images = {
    event: "Images/department.jpeg",
    logo: "Images/event-page.jpeg",
    about: "Images/about.jpeg",
  };

  const imageKeys = Object.keys(images);

  // Handle image auto-swipe every 2.5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      handleNext();
    }, 2500);
    return () => clearInterval(interval); // Cleanup on unmount
  }, [currentImage]);

  // Navigate to the next image
  const handleNext = () => {
    setFade(false); // Start fade-out
    setTimeout(() => {
      const currentIndex = imageKeys.indexOf(currentImage);
      const nextIndex = (currentIndex + 1) % imageKeys.length;
      setCurrentImage(imageKeys[nextIndex]);
      setFade(true); // Start fade-in
    }, 500); // Wait for fade-out duration
  };


  // Navigate to the previous image
  const handlePrev = () => {
    setFade(false); // Start fade-out
    setTimeout(() => {
      const currentIndex = imageKeys.indexOf(currentImage);
      const prevIndex = (currentIndex - 1 + imageKeys.length) % imageKeys.length;
      setCurrentImage(imageKeys[prevIndex]);
      setFade(true); // Start fade-in
    }, 500); // Wait for fade-out duration
  };
  // Swipe gesture handlers
  const swipeHandlers = useSwipeable({
    onSwipedLeft: handleNext, // Left swipe shows the next image
    onSwipedRight: handlePrev, // Right swipe shows the previous image
    trackMouse: true, // Enables swiping with a mouse (for testing)
  });

  const divRef = useRef(null);

  useEffect(() => {
    const target = divRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("visible");
          } else {
            entry.target.classList.remove("visible");
          }
        });
      },
      {
        threshold: 0.5,
      }
    );

    if (target) {
      observer.observe(target);
    }

    return () => {
      if (target) {
        observer.unobserve(target);
      }
    };
  }, []);

  return (
    <div>
      <div {...swipeHandlers} className="carousel">
        {/* Image Display */}
        <div className="image-container">
        <img
            src={images[currentImage]}
            alt="current display"
            className={`image ${fade ? "fade-in" : "fade-out"}`}
          />
        </div>

        {/* Dots for navigation */}
        <div className="dots">
          {imageKeys.map((key) => (
            <span
              key={key}
              className={`dot ${key === currentImage ? "active" : ""}`}
              onClick={() => setCurrentImage(key)}
            ></span>
          ))}
        </div>
      </div>

      <div className="welcome-banner">
        <span className="letter" style={{ backgroundColor: "red" }}>
          W
        </span>
        <span className="letter" style={{ backgroundColor: "orange" }}>
          E
        </span>
        <span className="letter" style={{ backgroundColor: "blue" }}>
          L
        </span>
        <span className="letter" style={{ backgroundColor: "red" }}>
          C
        </span>
        <span className="letter" style={{ backgroundColor: "orange" }}>
          O
        </span>
        <span className="letter" style={{ backgroundColor: "blue" }}>
          M
        </span>
        <span className="letter" style={{ backgroundColor: "red" }}>
          E
        </span>
        <span className="space"></span>
        <span className="letter" style={{ backgroundColor: "orange" }}>
          T
        </span>
        <span className="letter" style={{ backgroundColor: "blue" }}>
          O
        </span>
        <br />
        <span className="letters" style={{ color: "black" }}>
          N
        </span>
        <span className="letters" style={{ color: "black" }}>
          I
        </span>
        <span className="letters" style={{ color: "black" }}>
          S
        </span>
        <span className="letters" style={{ color: "black" }}>
          A
        </span>
        <span className="letters" style={{ color: "black" }}>
          D
        </span>
        <span className="letters" style={{ color: "black" }}>
          Y
        </span>
        <span className="letters" style={{ color: "black" }}>
          A
        </span>
      </div>

      {/* Scrolling Text Container with zoom-in effect */}
      <div className="zoom-in" ref={divRef}>
        <div
          style={{
            marginTop: "40px",
            display: "inline-block",
            textAlign: "left",
            fontSize: "1.3rem",
            fontFamily: "monospace",
            marginLeft: "30px",
            fontFamily: "Open Sans",
          }}
        >
          <span>
            <b>Nisadya 4th Edition </b>
          </span>
          <br />
          <span>Welcome to Nisadya 2025:</span>
          <br />
          <span>
            Organized by the Department of Management Studies, NIT Trichy, Nisadya
            is a
          </span>
          <br />
          <span>
            one-of-a-kind event that merges business brilliance with cultural
            vibrance.
          </span>
          <br />
          <i
            className="fas fa-briefcase"
            style={{ fontSize: "1rem", color: "brown" }}
          ></i>
          <span> Theme for this year:</span>
          <br />
          <span style={{ color: "blue" }}>
            "AI in Action: Leading with Responsible Business Innovations".
          </span>
          <br />
          <span>
            Explore how cutting-edge AI technologies are shaping the future of
            business
          </span>
          <br />
          <span>in Responsible and Impactful ways.</span>
        </div>
      </div>
    </div>
  );
};

export default EventImage;
