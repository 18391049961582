import React from 'react';
import './footer.css'; // Importing external CSS

const Footer = () => {
  return (
    <footer className="footer">
    <p style={{textAlign:'center',marginTop:'2%'}}> 2024 © All rights Reserved by <a
                href="https://cubeaisolutions.vercel.app/"
                sx={{ color: "blue", textDecoration: "underline" }}
              >< img src="/Images/cubeaisolutions.jpeg" alt="Logo" style={{width:'50px',height:'40px',borderRadius:'5px'}}/></a></p>
      <div className="footer-container">
        <div className="footer-item">
          📞 <a href="tel:+919486938781">9486938781</a>
        </div>
        <div className="footer-item">
          <span className="material-symbols-outlined email-icon">mail</span>
          <a href="mailto:contactus@cubeaisolution.com">contactus@cubeaisolution.com</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
