import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Eventdetails.css";
import { useNavigate } from "react-router-dom"; // Import useNavigate for routing
import { QRCodeCanvas } from 'qrcode.react'; 
import Modal from "react-modal";

function EventDetailexcel({ eventId }) {
	const [eventDetails, setEventDetails] = useState(null);
	const [scrollClass, setScrollClass] = useState("scroll-animation");
	const [error, setError] = useState(null);
	const [showVideo, setShowVideo] = useState(false);
	const [isVisible, setIsVisible] = useState(false);
	const [isQRModalOpen, setIsQRModalOpen] = useState(false);

	Modal.setAppElement("#root");
  
	const navigate = useNavigate(); // Get navigate function for routing

	const eventyoutube = {
		1: {
			youtube_title: "YOUTUBE LINK",
			youtube: "",
		},
	};

	const handleVideoClick = () => {
		setShowVideo(true);
	};

	function isElementInViewport(el) {
		const rect = el.getBoundingClientRect();
		return rect.top >= 0 && rect.bottom <= window.innerHeight;
	}

	// Function to handle the scroll event
	function handleScroll() {
		// Loop through elements with scroll-animation class
		document.querySelectorAll(".scroll-animation").forEach(function (element) {
			if (isElementInViewport(element)) {
				element.classList.add("visible");
			}
		});

		// Loop through elements with scroll-animation-right-to-left class
		document
			.querySelectorAll(".scroll-animation-right-to-left")
			.forEach(function (element) {
				if (isElementInViewport(element)) {
					element.classList.add("visible");
				}
			});
	}

	// Add event listener for scroll event
	window.addEventListener("scroll", handleScroll);

	// Trigger initial check in case elements are already visible
	handleScroll();

	useEffect(() => {
		/*if (eventId) {
      axios
        .get(`http://localhost:3001/fetch-excel/${eventId}`)
        .then((response) => {
          setEventDetails(response.data);
          setError(null);
        })
        .catch((error) => {
          console.error("Error fetching the event data:", error);
          setError("Error fetching event details");
        });
    }*/
		let mockEventDetails = [
			{
				id: "1",
				title: "Sankalp",
				description:
					`Sankalp: The Business Plan 
Challenges future business innovators to
turn their ideas into actionable strategies
and sustainable business models. Showcase
your entrepreneurial spirit and business
acumen.
From identifying market opportunities to
building sustainable business models, each
round will push you to think big and deliver
results.
Step up and seize the opportunity—Sankalp is
where your business journey begins!`,
				date: "",
				domain: "Business Plan",
				participants: "UG & PG",
				prize_money: "20k",
				rounds: { Total: "2" },
				Online: "1",
				Offline: "1",
				judges: "2",
				links:
					"https://forms.gle/3s1XiDxyCHg2gnRT9",
			},
			{
				id: "2",
				title: "Chanakya",
				description:
					`Ready to take your leadership skills to the
next level?
CHANAKYA: BEST MANAGER is where future
business leaders rise to the challenge!
This high-stakes competition will push your
decision-making, problem-solving, and
leadership abilities to the limit through
real-world business scenarios.
Step into the spotlight—CHANAKYA is your
moment to shine!`,
				date: "",
				domain: "Best Manager",
				participants: "PG",
				prize_money: "15k",
				rounds: { Total: "3" },
				Online: "NILL",
				Offline: "1-2-3",
				judges: "2",
				links:
					"https://forms.gle/3s1XiDxyCHg2gnRT9",
			},
			{
				id: "3",
				title: "Merx",
				description:
					`MERX is the ultimate marketing competition,
where creative minds craft cutting-edge
campaigns and solve real-world challenges.
From digital innovation to brand
storytelling, every round of MERX will push
you to think outside the box and create
campaigns that leave a lasting impact.
Impress a panel of industry experts with your
creative flair and strategic insights, and
prove that you have what it takes to lead the
future of marketing.
Unleash your potential—MERX is your stage to
shine as the next marketing mastermind!`,
				date: "",
				domain: "Marketing",
				participants: "UG & PG",
				prize_money: "17k",
				rounds: { Total: "3" },
				Online: "1",
				Offline: "2-3",
				judges: "2",
				links:
					"https://forms.gle/3s1XiDxyCHg2gnRT9",
			},
			{
				id: "4",
				title: "Praxis",
				description:
					`Praxis tests your operational expertise
through real-world scenarios, focusing on
efficiency and problem-solving in business
processes.
From optimizing supply chains to streamlining
processes, every round will push your ability
to manage resources, minimize costs, and
deliver results under pressure. Impress a
panel of industry experts with your ability
to solve complex operational challenges and
showcase your expertise in driving business
success.
Praxis is where operational leaders rise—step
up, take the challenge, and lead the way to
operational mastery!
`,
				date: "",
				domain: "Operations",
				participants: "PG",
				prize_money: "17k",
				rounds: { Total: "3" },
				Online: "1",
				Offline: "2-3",
				judges: "2",
				links:
					"https://forms.gle/3s1XiDxyCHg2gnRT9",
			},
			{
				id: "5",
				title: "Vriddhi",
				description:
					`Vriddhi is a premier competition for finance
professionals, challenging participants with
real-world financial scenarios and strategic
solutions.
Challenge yourself to analyze case studies,
develop strategic solutions, and demonstrate
your ability to drive financial growth.
Impress a panel of industry experts with your
insights and prove you have what it takes to
excel in the fast-paced world of finance.
Join us—Vriddhi is where the future of
finance unfolds!
`,
				date: "",
				domain: "Finance",
				participants: "PG",
				prize_money: "17k",
				rounds: { Total: "3" },
				Online: "1",
				Offline: "2-3",
				judges: "2",
				links:
					"https://forms.gle/3s1XiDxyCHg2gnRT9",
			},
			{
				id: "6",
				title: "Pravaran",
				description:
					`Pravaran tests your expertise in HR
management, with real-world challenges in
people management, culture development, and
organizational success.
Each round of Pravaran will test your ability
to create strategies that empower teams,
enhance productivity, and drive
organizational success. Impress a panel of HR
experts with your innovative solutions and
prove that you have what it takes to lead in
today’s dynamic workforce landscape.
Step into the spotlight—Pravaran is where the
next generation of HR leaders emerges!`,
				date: "",
				domain: "HR",
				participants: "PG",
				prize_money: "17k",
				rounds: { Total: "3" },
				Online: "1",
				Offline: "2-3",
				judges: "2",
				links:
					"https://forms.gle/3s1XiDxyCHg2gnRT9",
			},
			{
				id: "7",
				title: "Strategy Sprint",
				description:
					`Strategy Sprint is a fast-paced consulting
competition, challenging participants to
solve complex business problems under time
pressure.
From market entry strategies to operational
turnarounds, each round of Strategy Sprint
will test your ability to deliver quick,
impactful recommendations that drive results.
Impress a panel of top-tier consultants and
industry leaders with your insights and
showcase your potential to thrive in the
fast-paced world of consulting.
This is your moment—Strategy Sprint is where
future consulting champions rise to the
challenge!`,
				date: "",
				domain: "Consulting",
				participants: "UG & PG",
				prize_money: "17k",
				rounds: { Total: "3" },
				Online: "1",
				Offline: "2-3",
				judges: "2",
				links:
					"https://forms.gle/3s1XiDxyCHg2gnRT9",
			},
			{
				id: "8",
				title: "Serpentize",
				description:
					`Serpentize is an IPL-themed event where
participants draft and manage a dream cricket
team in a thrilling auction.
This dynamic competition will challenge your
analytical skills, player assessment
abilities, and strategic planning as you
draft and manage your dream team.
Step up to the crease—Serpentine Draft is
your chance to showcase your cricketing
prowess and strategy in the high-stakes world
of IPL!`,
				date: "",
				domain: "IPL ",
				participants: "UG & PG",
				prize_money: "9k",
				rounds: { Total: "2" },
				Online: "NILL",
				Offline: "1-2",
				judges: "0",
				links:
					"https://forms.gle/3s1XiDxyCHg2gnRT9",
			},
			{
				id: "9",
				title: "Uttar",
				description:
					`Uttar: B Quiz is a competition testing
knowledge across diverse topics, from current
affairs to pop culture.
This exciting event will test your knowledge
across a wide range of topics, from current
affairs and history to science and pop
culture.
Join us for an exhilarating experience—Uttar
B Quiz is where the brightest minds compete
for the title of quiz champion!`,
				date: "",
				domain: "B - Quiz",
				participants: "UG & PG",
				prize_money: "5k",
				rounds: '{"Total":"2"}',
				Online: "NILL",
				Offline: "1-2",
				judges: "0",
				links:
					"https://forms.gle/3s1XiDxyCHg2gnRT9",
			},

			{
				id: "10",
				title: "Gavel",
				description:
					`Gavel is a Debate competition testing your
skills in argumentation, critical thinking,
and discourse on thought-provoking topics.
This dynamic competition will test your
ability to construct compelling arguments,
think on your feet, and engage in respectful
discourse.
Step into the arena—Gavel is where future
leaders and communicators rise to the
occasion!`,
				date: "",
				domain: "Debate",
				participants: "UG & PG",
				prize_money: "3k",
				rounds: { Total: "2" },
				Online: "NILL",
				Offline: "1-2",
				judges: "1",
				links:
					"https://forms.gle/3s1XiDxyCHg2gnRT9",
			},
			{
				id: "11",
				title: "Manifest",
				description:
					`Manifest: Business Charades, blends fun and
strategy as participants act out business
concepts using gestures and expressions.
In this engaging competition, participants
will act out business terms, concepts, and
scenarios without speaking, using only
gestures and expressions to convey their
ideas.
Join us for an unforgettable experience—
Manifest Business Charades is where fun meets
business savvy, and every gesture counts!`,
				date: "",
				domain: "Innovation",
				participants: "UG & PG",
				prize_money: "3k",
				rounds: { Total: "2" },
				Online: "NILL",
				Offline: "1-2",
				judges: "2",
				links:
					"https://forms.gle/3s1XiDxyCHg2gnRT9",
			},
			
			{
				id: "12",
				title: "Quiz",
				description:
				[
					"Sports Quiz",
					"Brand Quiz",
					"AI ML Quiz"
				  ],
				date: "",
				domain: "Branding",
				participants: "UG & PG",
				prize_money: "Prize in Kind",
				rounds: { Total: "1" },
				Online: "1",
				Offline: "NILL",
				judges: "0",
				links:
					"https://forms.gle/3s1XiDxyCHg2gnRT9",
			},
			
		];

		const selectedEvent = mockEventDetails.find(
			(event) => event.id === eventId
		);

		if (selectedEvent) {
			setEventDetails(selectedEvent);
		} else {
			setError("Event not found");
		}
	}, [eventId]);
	const openQRModal = () => setIsQRModalOpen(true);
  const closeQRModal = () => setIsQRModalOpen(false);


	return (
		<>
			<header
				id="navigation"
				className="navbar-inverse navbar-fixed-top animated-header"
			>
				<div className="title-bar">
					<div className="containerlogo">
						<div className="navbar-brand">
							<a href="/">
								<img
									src="/Images/titlelogo.png"
									alt="Logo"
									style={{ width: "200px" }}
								/>
							</a>
						</div>
					</div>
				</div>
				<button 
            onClick={openQRModal} 
            className="register-button" 
            style={{
              marginLeft: "auto",
              padding: "10px 20px",
              borderRadius: "50px",
              backgroundColor: "#007bff",
              color: "#fff",
              border: "none",
              cursor: "pointer"
            }}
          >
            Register
          </button>
				
			</header>

			{eventDetails ? (
				<div className="container">
					<div className="background-container">
						<div className="text-over1">
							<div className="event-details">
							<Modal
                isOpen={isQRModalOpen}
                onRequestClose={closeQRModal}
                style={{
                  overlay: { backgroundColor: "rgba(0, 0, 0, 0.75)" },
                  content: { width: "300px", margin: "auto", padding: "20px", textAlign: "center" },
                }}
              >
                <h2>Scan QR Code to Register</h2>
                {eventDetails.links && (
                  <QRCodeCanvas 
                    value={eventDetails.links} 
                    size={200} 
                    style={{ margin: "20px 0" }} 
                  />
                )}
                <button 
                  onClick={closeQRModal} 
                  style={{
                    marginTop: "20px",
                    padding: "10px 20px",
                    borderRadius: "50px",
                    backgroundColor: "#007bff",
                    color: "#fff",
                    border: "none",
                    cursor: "pointer"
                  }}
                >
                  Close
                </button>
              </Modal>

								{error ? (
									<p className="error">{error}</p>
								) : (
									<>
										<div style={{ overflowY: "auto", padding: "4rem",display:"center" }}>
											<h1
												className="scroll-animation-right-to-left"
												style={{ fontSize: "2rem" }}
											>
												{eventDetails.title}
											</h1>
											<h2 className="scroll-animation-right-to-left">
												Description:
												
											</h2>
											<p className="scroll-animation-right-to-left">
    {eventDetails.description && Array.isArray(eventDetails.description) ? (
        <ul>
            {eventDetails.description.slice(0, 13).map((point, index) => (
                <li key={index}>{point}</li>
            ))}
        </ul>
    ) : (
        <span>{eventDetails.description}</span>
    )}
</p>
											
											<h2 className="scroll-animation-right-to-left">
												Domain:
											</h2>
											<p className="scroll-animation-right-to-left">
												{eventDetails.domain}
											</p>
											<h2 className="scroll-animation-right-to-left">
												Participants:
											</h2>
											<p className="scroll-animation-right-to-left">
												{eventDetails.participants}
											</p>
											<h2 className="scroll-animation-right-to-left">
												Prize Money:
											</h2>
											<p className="scroll-animation-right-to-left">
												{eventDetails.prize_money}
											</p>
											<h2 className="scroll-animation-right-to-left">
												Rounds:
											</h2>
											<p className="scroll-animation-right-to-left">
												Rounds: {eventDetails.rounds.Total}
											</p>
											<h2 className="scroll-animation-right-to-left">
												Online:
											</h2>
											<p className="scroll-animation-right-to-left">
											Round : {eventDetails.Online} 
											</p>
											<h2 className="scroll-animation-right-to-left">
												Offline:
											</h2>
											<p className="scroll-animation-right-to-left">
											Round : {eventDetails.Offline} 
											</p>
											<h2 className="scroll-animation-right-to-left">
												Judges:
											</h2>
											<p className="scroll-animation-right-to-left">
												{eventDetails.judges} Judges
											</p>
											<h2 className="scroll-animation-right-to-left">Links:</h2>
										</div>
										<a
											href={eventDetails.links}
											target="_blank"
											rel="noopener noreferrer"
											style={{ fontSize: "30px", paddingLeft: "20px" }}
											className="scroll-animation-right-to-left"
										>
											Event Link
										</a>
									</>
								)}
							</div>
						</div>

						<div className="button-wrapper" >
							<button onClick={() => navigate("/")} className="video-button">
								<strong>Back to Home</strong>
							</button>
						</div>
					</div>
				</div>
			) : (
				<p>Loading event details...</p>
			)}
		</>
	);
}

export default EventDetailexcel;
