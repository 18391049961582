import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "./Eventlist.css"; // Custom styles for event list
import Home from "./Home";
import Schedule from "./Schedule";
import EventCard from "./EventCard";

function EventList() {
	const [events, setEvents] = useState([]); // State to store the event details
	const [error, setError] = useState(null); // State for handling errors
	const [loading, setLoading] = useState(true); // State for loading indication
	const [showVideo, setShowVideo] = useState(false);

	const eventyoutube = {
		youtube: "https://www.youtube.com/embed/VIDEO_ID",
		youtube_title: "Your Video Title",
	};

	const handleVideoClick = () => {
		setShowVideo(!showVideo);
	};

	const navigate = useNavigate();

	// Fetch event details from the backend API
	useEffect(() => {
		// Fetch event details from the backend API

		/*axios
        .get(`http://localhost:3001/fetch-eventlists`) // Fetch event data
        .then((response) => {
          console.log("Event data fetched:", response.data); // Log the response to verify data
          setEvents(response.data.events); // Save event data to state
          setLoading(false); // Stop loading once the data is fetched
        })
        .catch((error) => {
          console.error("Error fetching the event data:", error);
          setError("Error fetching event details"); // Handle error case
          setLoading(false); // Stop loading on error
        });*/
		let eventResponse = [
			[
				{
					id: "1",
					event: "Sankalp",
					description:
						`Sankalp: The Business Plan
Challenges future business innovators to
turn their ideas into actionable strategies
and sustainable business models .........`,
					link: "https://unstop.com/c/department-of-management-studies-doms-national-institute-of-technology-nit-trichy-placement-interview-competitions-articles-videos-17105",
					icon: "fas fa-lightbulb fa-3x",
				},
				{
					id: "2",
					event: "Chanakya",
					description:
						`Ready to take your leadership skills to the
next level?
CHANAKYA: BEST MANAGER is where future
business leaders rise to the challenge! .........`,
					link: "https://unstop.com/c/department-of-management-studies-doms-national-institute-of-technology-nit-trichy-placement-interview-competitions-articles-videos-17105",
					icon: "fas fa-bullseye fa-3x",
				},
			],
			[
				{
					id: "3",
					event: "Merx",
					description:
						`MERX is the ultimate marketing competition,
where creative minds craft cutting-edge
campaigns and solve real-world challenges .........`,
					link: "https://unstop.com/c/department-of-management-studies-doms-national-institute-of-technology-nit-trichy-placement-interview-competitions-articles-videos-17105",
					icon: "fas fa-chart-bar fa-3x",
				},
				{
					id: "4",
					event: "Praxis",
					description:
						`Praxis tests your operational expertise
through real-world scenarios, focusing on
efficiency and problem-solving in business
processes  .........`,
					link: "https://unstop.com/c/department-of-management-studies-doms-national-institute-of-technology-nit-trichy-placement-interview-competitions-articles-videos-17105",
					icon: "fas fa-cogs fa-3x",
				},
			],
			[
				{
					id: "5",
					event: "Vriddhi",
					description:
					`Vriddhi is a premier competition for finance
					professionals, challenging participants with
					real-world financial scenarios and strategic
					solutions  .........`,
					link: "https://unstop.com/c/department-of-management-studies-doms-national-institute-of-technology-nit-trichy-placement-interview-competitions-articles-videos-17105",
					icon: "fas fa-seedling fa-3x",
				},
				{
					id: "6",
					event: "Pravaran",
					description:
						`Pravaran tests your expertise in HR
management, with real-world challenges in
people management, culture development, and
organizational success  .........`,
					link: "https://unstop.com/c/department-of-management-studies-doms-national-institute-of-technology-nit-trichy-placement-interview-competitions-articles-videos-17105",
					icon: "fas fa-pencil-ruler fa-3x",
				},
			],
			[
				{
					id: "7",
					event: "Strategy Sprint",
					description:
						`Strategy Sprint is a fast-paced consulting
competition, challenging participants to
solve complex business problems under time
pressure   .........`,
					link: "https://unstop.com/c/department-of-management-studies-doms-national-institute-of-technology-nit-trichy-placement-interview-competitions-articles-videos-17105",
					icon: "fas fa-wallet fa-3x",
				},
				{
					id: "8",
					event: "Serpentize",
					description:
						`Serpentize is an IPL-themed event where
participants draft and manage a dream cricket
team in a thrilling auction.This dynamic competition  .........`,
					link: "https://unstop.com/c/department-of-management-studies-doms-national-institute-of-technology-nit-trichy-placement-interview-competitions-articles-videos-17105",
					icon: "fas fa-chess fa-3x",
				},
			],
			[
				{
					id: "9",
					event: "Uttar",
					description:
						`Uttar: B Quiz is a competition testing
knowledge across diverse topics, from current
affairs to pop culture.This  .........`,
					link: "https://unstop.com/c/department-of-management-studies-doms-national-institute-of-technology-nit-trichy-placement-interview-competitions-articles-videos-17105",
					icon: "fas fa-comments fa-3x",
				},
				{
					id: "10",
					event: "Gavel",
					description:
						`Gavel is a Debate competition testing your
skills in argumentation, critical thinking,
and discourse on   .........`,
					link: "https://unstop.com/c/department-of-management-studies-doms-national-institute-of-technology-nit-trichy-placement-interview-competitions-articles-videos-17105",
					icon: "fas fa-balance-scale fa-3x",
				},
			],
			[
				{
					id: "11",
					event: "Manifest",
					description:
						`Manifest: Business Charades, .........`,
					link: "https://unstop.com/c/department-of-management-studies-doms-national-institute-of-technology-nit-trichy-placement-interview-competitions-articles-videos-17105",
					icon: "fas fa-bolt fa-3x",
				},
				{
					id: "12",
					event: "Quiz",
					description:
						`Sports Quiz
Brand Quiz
AI ML Quiz`,
					link: "https://unstop.com/c/department-of-management-studies-doms-national-institute-of-technology-nit-trichy-placement-interview-competitions-articles-videos-17105",
					icon: "fas fa-trophy fa-3x",
				},
			],
			
		];
		setEvents(eventResponse);
		setLoading(false);
	}, []);

	return (
		<>
			<Home />
			<div>
				{loading && <p>Loading event details...</p>}{" "}
				{/* Show loading message */}
				{error && <p className="error">{error}</p>} {/* Show error message */}
				{events.length > 0 && !loading ? (
					<div className="event-list-container">
						{events.map((event, ind) => (
							<EventCard key={ind} event={event} />
						))}
					</div>
				) : (
					!loading && !error && <p>No event found</p>
				)}
			</div>

			<div className="video-container">
				{/* {!showVideo && ( // Show the button only if the video is not displayed
        <button className="video-button" onClick={handleVideoClick}>
          Watch Video
        </button>
      )} */}
				{showVideo && (
					<iframe
						src={eventyoutube.youtube}
						title={eventyoutube.youtube_title}
						frameBorder="0"
						allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
						allowFullScreen
						className="video-iframe"
					></iframe>
				)}

				<Schedule />
			</div>
		</>
	);
}

export default EventList;
