import React, { useEffect, useState } from "react";
import "./Schedule.css"; // Optional: For styling
import { useNavigate } from "react-router-dom"; // Import useNavigate for routing
import axios from "axios"; // To make HTTP requests

const Schedule = () => {
	const navigate = useNavigate(); // Get navigate function for routing
	const [scheduleData, setScheduleData] = useState([]); // Store schedule data
	const [loading, setLoading] = useState(true); // Loading state
	const [error, setError] = useState(null); // Error state

	// Fetch schedule data on component mount
	useEffect(() => {
		// Simulating API call with a hardcoded Schedule (for testing)
		const schedule = [
			{
				id: "1",
				day: "10/01/2025",
				event: "Sankalp",
				prizemoney: "17k",
				registerLink:
					"https://forms.gle/3s1XiDxyCHg2gnRT9",
			},
			{
				id: "2",
				day: "10/01/2025",
				event: "Chanakya",
				prizemoney: "15k",
				registerLink:
					"https://forms.gle/3s1XiDxyCHg2gnRT9",
			},
			{
				id: "3",
				day: "10/01/2025",
				event: "Merx",
				prizemoney: "17k",
				registerLink:
					"https://forms.gle/3s1XiDxyCHg2gnRT9",
			},
			{
				id: "4",
				day: "10/01/2025",
				event: "Praxis",
				prizemoney: "17k",
				registerLink:
					"https://forms.gle/3s1XiDxyCHg2gnRT9",
			},
			{
				id: "5",
				day: "10/01/2025",
				event: "Vriddhi",
				prizemoney: "17k",
				registerLink:
					"https://forms.gle/3s1XiDxyCHg2gnRT9",
			},
			{
				id: "6",
				day: "11/01/2025",
				event: "Pravaran",
				prizemoney: "17k",
				registerLink:
					"https://forms.gle/3s1XiDxyCHg2gnRT9",
			},
			{
				id: "7",
				day: "11/01/2025",
				event: "Strategy Sprint",
				prizemoney: "17k",
				registerLink:
					"https://forms.gle/3s1XiDxyCHg2gnRT9",
			},
			{
				id: "8",
				day: "11/01/2025",
				event: "Serpentine Draft",
				prizemoney: "9k",
				registerLink:
					"https://forms.gle/3s1XiDxyCHg2gnRT9",
			},
			{
				id: "9",
				day: "10/01/2025",
				event: "Uttar",
				prizemoney: "5k",
				registerLink:
					"https://forms.gle/3s1XiDxyCHg2gnRT9",
			},
			{
				id: "10",
				day: "11/01/2025",
				event: "Gavel",
				prizemoney: "3k",
				registerLink:
					"https://forms.gle/3s1XiDxyCHg2gnRT9",
			},
			{
				id: "11",
				day: "11/01/2025",
				event: "Manifest",
				prizemoney: "3k",
				registerLink:
					"https://forms.gle/3s1XiDxyCHg2gnRT9",
			},
			{
				id: "12",
				day: "Virtual",
				event: "Sports Quiz",
				prizemoney: "Prize in Kind",
				registerLink:
					"https://forms.gle/3s1XiDxyCHg2gnRT9",
			},
			{
				id: "13",
				day: "Virtual",
				event: "Brand Quiz",
				prizemoney: "Prize in Kind",
				registerLink:
					"https://forms.gle/3s1XiDxyCHg2gnRT9",
			},
			{
				id: "14",
				day: "Virtual",
				event: "AI ML Quiz",
				prizemoney: "Prize in Kind",
				registerLink:
					"https://forms.gle/3s1XiDxyCHg2gnRT9",
			},
		];

		setScheduleData(schedule);
		setLoading(false); // Set loading to false after fetching data
	}, []); // Empty dependency array ensures it runs only once when the component mounts

	// Get today's date to highlight today's event
	const today = new Date();
	const formattedDate = `${
		today.getMonth() + 1
	}/${today.getDate()}/${today.getFullYear()}`;

	// Handle clicking on a row, navigate to event details
	const handleRowClick = (eventId) => {
		navigate(`/event/${eventId}`);
	};

	return (
		<div className="schedule-container">
			<h5 className="schedule">SCHEDULE</h5>

			{loading ? (
				<p>Loading...</p> // Show loading text if data is still being fetched
			) : error ? (
				<p>{error}</p> // Show error message if data fetch fails
			) : (
				<table className="schedule-table">
					<thead>
						<tr>
							<th>Date</th>
							<th>Event</th>
							<th>Prize Money</th>
							<th>View Details</th>
							<th>Register Link</th>
						</tr>
					</thead>
					<tbody>
						{scheduleData.map((item) => {
							const isToday = item.day === formattedDate; // Check if the event is today
							return (
								<tr
									key={item.id} // Use unique id as the key
									className={isToday ? "highlight" : ""} // Highlight row if it's today's event
									onClick={() => handleRowClick(item.id)} // Navigate to event details when row is clicked
								>
									<td className={isToday ? "highlight-day" : ""}>{item.day}</td>
									<td className={isToday ? "highlight-event" : ""}>
										{item.event}
									</td>
									<td className={isToday ? "highlight-event" : ""}>
										{item.prizemoney}
									</td>

									<td className={isToday ? "highlight-event" : ""}>
										<button
											onClick={(e) => {
												e.stopPropagation(); // Prevent row click handler from firing
												handleRowClick(item.id);
											}}
										>
											View Details
										</button>
									</td>

									<td className={isToday ? "highlight-event" : ""}>
										{/* Check if registerLink exists and display it as a clickable link */}
										{item.registerLink ? (
											<a
												href={item.registerLink}
												target="_blank"
												rel="noopener noreferrer"
												onClick={(e) => e.stopPropagation()} // Prevent row click handler from firing when clicking on the link
											>
												Register Here
											</a>
										) : (
											<p className="eventlink">
												No registration link available
											</p> // Fallback if no registerLink
										)}
									</td>
								</tr>
							);
						})}
					</tbody>
				</table>
			)}

			<div className="button-wrapper"></div>
		</div>
	);
};

export default Schedule;
